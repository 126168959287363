import { useState, useEffect } from 'react';

const ImageLoader = ({ img, onDoubleClick, secondaryImg }) => {
	const [loading, setLoading] = useState(true);
	const [message, setMessage] = useState('');

	useEffect(() => {
		setLoading(true);
		if (!img) {
			setMessage('No image found');
			setLoading(false);
		} else if (!img.image_path || img.image_path === '0') {
			setMessage('Image is being processed');
			setLoading(false);
		} else if (img.status === 'invalidated') {
			setMessage(`This image is invalid. Reason: ${img.reason.join(', ')}`);
			setLoading(false);
		} else if (img.status === 'invalid') {
			setMessage('This image is being validated');
			setLoading(false);
		}else{
			setMessage("");
		}
	}, [img]);

	const handleImageLoaded = () => {
		setLoading(false);
	};

	const handleDoublieClick = () => {
		if(onDoubleClick){
			onDoubleClick();
		}
  	}

	return (
		<div className="relative flex items-center justify-center" style={{ height: '419px'  }}>
			{loading && (
				<div className="absolute flex items-center justify-center w-16">
					<div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-300"></div>
				</div>
			)}
			{message ? (
				<p className="text-center text-gray-400">{message}</p>
			) : (
				<div className='relative m-2 2xl:m-8 flex items-center justify-center'>
					<img
						src={img?.image_path}
						alt={`Paint ${img?.paint} - ${img?.mainType} ${img?.subType}`}
						className="cursor-pointer box-border"
						onLoad={handleImageLoaded}
						style={{ display: loading ? 'none' : 'block',  maxHeight: '419px'}}
						onDoubleClick={handleDoublieClick}
					/>
					{secondaryImg && secondaryImg?.image_path  && secondaryImg?.image_path !== '0' && (
						<img
							src={secondaryImg?.image_path}
							className={`${loading ? 'hidden' : 'block'} cursor-pointer box-border`}
							style={{
								position: 'absolute',
								top: 0,
								// left: img?.scalp_position.x_start / 10 + '%',
								height: '100%',
								objectFit: 'cover',
							}}
							onDoubleClick={handleDoublieClick}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default ImageLoader;

import React, { useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import CrossGrey from "../../assets/images/CrossGrey.svg";
import lessThanIcon from "../../assets/images/lessThanIcon.svg";
import greaterThanIcon from "../../assets/images/greaterThanIcon.svg";
import ImageLoader from '../ImageLoader';
import dayjs from 'dayjs';
import { ChangeAnalysisContext } from '../../views/OnePageConsult';


const GlobalImageCompare = ({ analyses }) => {
	const history = useHistory();

    const { patient_id, analysis_id } = useParams();
	const analysis_id_int = parseInt(analysis_id);

	const { changeAnalysis } = useContext(ChangeAnalysisContext);

	return (
		<div>
			<div className='absolute top-5 right-5'>
				<Link to={`/patient/${patient_id}/consult/${analysis_id}/global`}>
					<img src={CrossGrey} className='h-5 w-5' />
				</Link>
			</div>
			<div class="text-center font-bold text-base font-sans tracking-wider text-text-15 opacity-80 mt-12">
				Global Hair Growth Tracking
			</div>
			<div className='text-center font-normal text-base font-sans tracking-wider text-text-15 opacity-80 mb-10'>
				(Crown)
			</div>
			<div className='flex items-center justify-center'>
				<button
					className="text-2xl focus:outline-none"
					onClick={function () {
						changeAnalysis(history, 'prev', analysis_id_int, analyses);
					}}
				>
					<img src={lessThanIcon} className='w-10 h-10'/>
				</button>
				<div className='grid justify-center flex-grow grid-cols-3'>
					{analyses.map((analysis, index) => {
						if(index === analysis_id_int - 1 || index === analysis_id_int || index === analysis_id_int + 1){
							return (
								<div
									className={`flex flex-col items-center justify-center px-4 py-4 ${analysis_id_int === 0 && index === 0 ? 'col-start-2' : ''}`}
								>
									<div className="text-center">
										{analysis?.images?.global && <div className='mb-10'><ImageLoader img={analysis?.images?.global?.ml} /></div>}
										<p className='text-center font-normal font-bold text-xl leading-5 tracking-wider' style={{ color: '#707D87' }}>
											{dayjs
											.unix(analysis.created_at)
											.format("MM-DD-YY")}: 
										<span className='text-center font-normal font-bold text-2xl leading-5 tracking-tighter opacity-80' style={{ color: '#F6F7FA' }}>0</span></p>
									</div>
								</div>
							);
						} 
					})}
				</div>
				<button
					className="text-2xl focus:outline-none"
					onClick={function () {
						changeAnalysis(history, 'next', analysis_id_int, analyses);
					}}
				>
					<img src={greaterThanIcon} className='w-10 h-10'/>
				</button>
			</div>
		</div>
	)
}

export default GlobalImageCompare
import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import ShowHeadAvatar from './ShowHeadAvatar';
import { Bar } from 'react-chartjs-2';
import ImageLoader from '../ImageLoader';
import dayjs from 'dayjs';


const ThreexCloseupImageView = ({ analysis, allAnalysis }) => {
	const history = useHistory();

    const { patient_id, analysis_id, subType } = useParams();
	const analysis_id_int = parseInt(analysis_id);

	// const displayImage = () => {

	// 	let src = "";

	// 	analysis?.images.api.map((img) => {
	// 		if(img.paint === 2){
	// 			src = img.image_path;
	// 		}
	// 	});

	// 	if(src){
	// 		return <img src={src} className='w-full'/>
	// 	}else{
	// 		return <p> Image Not Found</p>
	// 	}
	// }

	return (
		<div>
			<div class="text-center font-normal font-bold text-base leading-5 tracking-wider mt-12 mb-8 capitalize opacity-90" style={{ fontFamily: 'Arial', letterSpacing: '0.5px', color: '#D1D7DA'}}>
				{subType}
			</div>
			
			<div className='mx-16 flex gap-16'>
				<div className='w-1/3'>
					<div className="text-left font-normal text-white text-opacity-90 text-sm leading-5 tracking-wider" style={{ fontFamily: 'Roboto', letterSpacing: '0.8px' }}>
						Hair Density (HD)
					</div>
					<div className='w-full'>
						<Bar
							id="1"
							className="h-96"
							data={{
								labels: allAnalysis?.map((analysis) => {
									return dayjs
									.unix(analysis.created_at)
									.format("MM-DD-YY");
								}), // array of labels
								datasets: [
								  {
									label: '(/cm2)', // label for the dataset
									data: allAnalysis?.map((analysis) => {
										return analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['all'];
									}), // array of data points
									backgroundColor: allAnalysis?.map((analysis, index) => {
										if (index === analysis_id_int) {
											return '#ef8679';
										}
										return '#7a686f';
									}), // array of background colors
									borderColor: allAnalysis?.map((analysis, index) => {
										if (index === analysis_id_int) {
											return '#ef8679';
										}
										return '#7a686f';
									}), // array of border colors
									borderWidth: 1, // width of the borders
								  },
								],
							  }}
							options={{
								plugins: {
								datalabels: {
									display: true,
									color: 'white',
									align: 'end',
									anchor: 'end',
								},
								},

								scales: {
								xAxes: [
									{
									ticks: {
										userCallback: function (l, index, labels) {
										if (
											index === analysis_id ||
											index === 0 ||
											index === 3
										) {
											return l;
										}
										return '';
										},
									},
									gridLines: {
										display: true,
										drawBorder: true,
										drawOnChartArea: false,
										color: '#4b535a',
										lineWidth: 2,
									},
									},
								],
								yAxes: [
									{
									ticks: {
										beginAtZero: true,
										userCallback: function (l, index, labels) {
										return l;
										},
										min: 0,
										max: 200,
										stepSize: 100,
									},
									gridLines: {
										display: true,
										drawBorder: true,
										drawOnChartArea: false,
										color: '#4b535a',
										lineWidth: 2,
									},
									},
								],
								},

								legend: {
								labels: {
									boxWidth: -15,
									fontSize: 15,
									fontColor: '#bfc5ca',
								},

								align: 'start',
								onClick: '',
								},
								width: '100%',
							}}
						/>
					</div>


					<div className="text-left font-normal text-white text-opacity-90 text-sm leading-5 tracking-wider mt-12" style={{ fontFamily: 'Roboto', letterSpacing: '0.8px' }}>
						Hair Thickness (HT)
					</div>
					<div lassName='w-full'>
						<Bar
							id="2"
							data={{
								labels: allAnalysis?.map((analysis) => {
									return dayjs
									.unix(analysis.created_at)
									.format("MM-DD-YY");
								}), // array of labels
								datasets: [
								  {
									label: '(µm)', // label for the dataset
									data: allAnalysis?.map((analysis) => {
										return analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['hairDiameter'] * 1000;
									}), // array of data points
									backgroundColor: allAnalysis?.map((analysis, index) => {
										if (index === analysis_id_int) {
											return '#3de2d2';
										}
										return '#267c7f';
									}), // array of background colors
									borderColor: allAnalysis?.map((analysis, index) => {
										if (index === analysis_id_int) {
											return '#3de2d2';
										}
										return '#267c7f';
									}), // array of border colors
									borderWidth: 1, // width of the borders
								  },
								],
							}}
							options={{
								plugins: {
								datalabels: {
									display: true,
									color: 'white',
									align: 'end',
									anchor: 'end',
								},
								},

								scales: {
								xAxes: [
									{
									ticks: {
										userCallback: function (l, index) {
										if (
											index === analysis_id ||
											index === 0 ||
											index === 3
										) {
											return l;
										}
										return '';
										},
									},
									gridLines: {
										display: true,
										drawBorder: true,
										drawOnChartArea: false,
										color: '#4b535a',
										lineWidth: 2,
									},
									},
								],
								yAxes: [
									{
									ticks: {
										beginAtZero: true,
										userCallback: function (l, index, labels) {
										return l;
										},
										min: 0,
										max: 100,
										stepSize: 50,
									},
									gridLines: {
										display: true,
										drawBorder: true,
										drawOnChartArea: false,
										color: '#4b535a',
										lineWidth: 2,
									},
									},
								],
								},

								legend: {
									labels: {
										boxWidth: -15,
										fontSize: 15,
										fontColor: '#bfc5ca',
									},

									align: 'start',
									onClick: '',
								},
								width: '100%',
							}}
						/>
					</div>
				</div>
				<div className='w-1/3 flex flex-col justify-center align-center'>
					{/* {displayImage()} */}
					<div class="text-center font-normal text-sm leading-5 tracking-wider text-white opacity-70 mb-10 " style={{ fontFamily: 'Roboto', letterSpacing: '0.8px'}}>
						HD: {analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['all']}/cm2 (+12%) HT: {analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['hairDiameter'] * 1000}µm (+2%)
					</div>
					{/* <img src={Closeup} className='cursor-pointer' onDoubleClick={() => {history.push(`/patient/${patient_id}/study/${analysis_id_int}/compare/${subType}`)}}/> */}
					{analysis?.images?.['3x_closeup'].raw && <ImageLoader img={analysis?.images?.['3x_closeup']?.raw?.[subType]?.[0]} onDoubleClick={() => {history.push(`/patient/${patient_id}/study/${analysis_id_int}/compare/${subType}`)}} />}
				</div>
				<div className='w-1/3 flex justify-evenly flex-row'>
					<ShowHeadAvatar showHead={['left','right'].includes(subType) ? subType : 'top'} />
				</div>
			</div>
		</div>
	)
}

export default ThreexCloseupImageView
import React, { useState } from 'react';
import MaleHeadTop from "../../assets/images/MaleHeadTop.png";
import MaleHeadLeft from "../../assets/images/MaleHeadLeft.png";
import MaleHeadRight from "../../assets/images/MaleHeadRight.png";
import lessThanIcon from "../../assets/images/lessThanIcon.svg";
import greaterThanIcon from "../../assets/images/greaterThanIcon.svg";
import DotGrey from "../../assets/images/DotGrey.svg";
import DotTurquoise from "../../assets/images/DotTurquoise.svg";
import { Link, useParams } from 'react-router-dom';

const heads = [
	'left',
	'top',
	'right',
]

const ShowHeadAvatar = ({ showHead }) => {
	const { patient_id, analysis_id, subType } = useParams();
	const [currentHead, setCurrentHead] = useState(showHead);
	return (
		<div className="flex items-center justify-center w-full">
			<button
				className="font-bold text-2xl focus:outline-none"
				onClick={function () {
					const index = heads.findIndex(r => r === currentHead);
					if (index > 0) {
						setCurrentHead(heads[index - 1]);
					}
				}}
			>
				<img src={lessThanIcon} className="h-6 w-6" />
			</button>
			{
				currentHead === 'top' && 
				<div className='relative '>	
					<img src={MaleHeadTop} className="w-48 px-2" />
					<div className='absolute top-0 flex flex-col items-center justify-around w-full h-full pb-8'>
						<div>
							<Link to={`/patient/${patient_id}/consult/${analysis_id}/closeup/vertex`}>
								<ShowDot forType="vertex" subType={subType} />
							</Link>
						</div>
						<div>
							<Link to={`/patient/${patient_id}/consult/${analysis_id}/closeup/crown`}>
								<ShowDot forType="crown" subType={subType} />
							</Link>
						</div>
						<div>
							<Link to={`/patient/${patient_id}/consult/${analysis_id}/closeup/front`}>
								<ShowDot forType="front" subType={subType} />
							</Link>
						</div>
					</div>
				</div>
			}

			{
				currentHead === 'left' && 
				<div className='relative '>	
					<img src={MaleHeadLeft} className="w-48 px-2" />
					<div className='absolute top-0 flex flex-col items-center justify-around w-full h-full pb-8'>
						<div>
							<Link to={`/patient/${patient_id}/consult/${analysis_id}/closeup/left`}>
								<ShowDot forType="left" subType={subType} />
							</Link>
						</div>
						<div>
						</div>
						<div>
						</div>
					</div>
				</div>
			}

			{
				currentHead === 'right' && 
				<div className='relative '>	
					<img src={MaleHeadRight} className="w-48 px-2" />
					<div className='absolute top-0 flex flex-col items-center justify-around w-full h-full pb-8 "w-48 '>
						<div>
							<Link to={`/patient/${patient_id}/consult/${analysis_id}/closeup/right`}>
								<ShowDot forType="right" subType={subType} />
							</Link>
						</div>
						<div>
						</div>
						<div>
						</div>
					</div>
				</div>
			}
			<button
				className="font-bold text-2xl focus:outline-none"
				onClick={function () {
					const index = heads.findIndex(r => r === currentHead);
					if (index + 1 < heads.length) {
						setCurrentHead(heads[index + 1]);
					}
				}}
			>
				<img src={greaterThanIcon} className="h-6 w-6" />
			</button>
		</div>
	)
}

const ShowDot = ({ forType, subType }) => {
	if(forType === subType){
		return <img src={DotTurquoise} className='h-3 w-3 cursor-pointer' />
	}

	return <img src={DotGrey} className='h-3 w-3 cursor-pointer' />
};

export default ShowHeadAvatar
import React, { useEffect, useRef, useState } from 'react'
import styles from './Agreement.module.css'
import Logo from '../../assets/images/logo.png'
import { useHistory } from 'react-router-dom';
import Loading from '../../views/Loading';
import SignatureCanvas from 'react-signature-canvas'

function UserAgreement() {

    const history = useHistory();
    const [successMessage, setSuccessMessage] = useState("")
    const [ready, setReady] = useState(false);
    const [message, setMessage] = useState("");
    const signature = useRef(null);
    const [agreeTandC, setAgreeTandC] = useState(false);
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [printName, setPrintName] = useState("");


    useEffect(() => {
        setReady(true);
    }, []);

    const submitForm = (e) => {
        e.preventDefault()
        if(agreeTandC){
            setReady(false);
            fetch(`${process.env.REACT_APP_API}/clinic/save_user_agreement`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                body: JSON.stringify({
                    sign: signature.current.getTrimmedCanvas().toDataURL('image/png').substring(22),
                    print_name: printName,
                    title: title,
                    date: date
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    console.log(response);
                if (response.success) {
                    setSuccessMessage("User agreement Updated Successfully");
                    setTimeout(() => {
                        history.push("/onboarding/hipaa-agreement");
                    }, 1000);
                } else {
                    setMessage(response.message);
                }
                setReady(true);
                })
                .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
                setReady(true);
            });
        }
    }
  return (
    <>
        {ready && (
            <div>
                <div className={styles.topContainer}>
                    <img src={Logo} alt="logo" className={styles.logo} />
                    <div className={styles.title}>PACIFIC HAIR CENTER</div>
                </div>
                {message && <p className="mt-8 text-center text-red-600">{message}</p>}
                {successMessage && <p className="mt-8 text-center text-green-600">{successMessage}</p>}
                <form onSubmit={submitForm} className={styles.form}>
                    <div>USER AGREEMENT</div>
                    <div className={`${styles.agreement} text-justify bg-white text-black`}>
                        <div className='px-12 py-6'>
                            <h1 className='text-center italic mb-10 font-black'>GROTRACK PLATFORM LICENSE AGREEMENT</h1>
                            <p className='mb-4'>
                                THIS IS A LEGALLY BINDING AGREEMENT between Smart Practice Systems, Inc
                                (DBA: Gro Technologies) and you. BY CLICKING "I AGREE," OR BY OTHERWISE
                                SIGNING-UP OR FOR AN ACCOUNT, OR BY ACCESSING OR USING THE
                                SERVICES (DEFINED BELOW), YOU ARE ENTERING INTO THIS HEALTHCARE
                                PROVIDER USER AGREEMENT (THIS "AGREEMENT") AND YOU AGREE TO BE
                                BOUND BY ITS TERMS AND CONDITIONS.
                            </p>
                            <p className='mb-6'>
                                Please read this Agreement carefully, and do not sign-up for an account or use the Services
                                if you are unwilling or unable to be bound by this Agreement. You and we are collectively
                                referred to as the "Parties."
                            </p>
                            <h1 className='mb-6 font-black'>1. DEFINITIONS.</h1>
                            <p class="pl-10 mb-6">
                                (a) "Software" means the computer programs and documentation listed and described in
                                Schedule A attached to this Agreement. 
                            </p>
                            <p className='mb-6 '>
                                <span className='font-black'>2. GRANT OF RIGHTS. </span>
                                Licensor hereby grants to Licensee a nonexclusive license to install
                                and use the Software on up to 5 single-user computers in its possession.
                            </p>
                            <p className='mb-6 '>
                                <span className='font-black'>3. LICENSE TERM. </span>
                                This License is effective when executed by both parties and will last for a
                                term of 1 years. Thereafter, this License shall automatically be renewed for successive 1-year
                                terms unless Licensee gives Licensor written notice at least 60 days before the day on which the
                                license or renewal would expire of its intention not to renew this license. 

                            </p>
                            <p className='mb-6 '>
                                <span className='font-black'>4. LICENSE FEE. </span>
                                Licensee agrees to pay Licensor the following license fees: $199 per month. 
                            </p>
                            <p className='mb-6 '>
                                <span className='font-black'>5. TERMINATION. </span>
                                Licensor shall have the right to immediately terminate this License if
                                Licensee fails to perform any obligation required of Licensee under this License or if Licensee
                                becomes bankrupt or insolvent. 
                            </p>
                            <p className='mb-6 '>
                                <span className='font-black'>7. TITLE TO SOFTWARE. </span>
                                Licensor retains title to and ownership of the Software and all
                                enhancements, modifications and updates of the Software. 
                            </p>
                            <p className='mb-6 font-black'>
                                <span className='font-black'>8. DATA COLLECTION AND USE.  </span>
                                Licensor shall continue to collect patient
                                demographic data, health history data, clinical data (including exam data, treatment plan,
                                treatment records, treatment notes), hair images and hair analysis data during the License
                                Term. Licensor shall not maintain any patient personal identification. Certain data results
                                may be used or provided to other parties to develop hair growth technologies, products and
                                related technologies.
                            </p>
                            <ol class="list-decimal mx-16" type='1'>
                                <li className='my-4'>
                                    We collect patient demographic data (excluding personal identification data), health
                                    history data, clinical data (including exam data, treatment plan, treatment records,
                                    treatment notes) hair images and hair analysis data. Such data collected by Licensor not
                                    have any patient’s personal, identifiable. information attached. 
                                </li>
                                <li className='my-4'>
                                    We use the hair images and hair analysis data we collect to improve our hair analysis
                                    software quality and capabilities.          
                                </li>
                                <li className='my-4'>
                                    We use patient demographic data, health history and clinical data to create predictive
                                    algorithms that can assist our doctor users optimize treatment protocol and improve
                                    treatment efficacy.
                                </li>
                                <li className='my-4'>
                                    We may also license the data we collect from GroTrack platform to third party
                                    technology companies to develop hair growth technologies, products and related
                                    technologies, products.
                                </li>
                            </ol>
                            <p className='mb-6 '>
                                <span className='font-black'>9. MODIFICATIONS AND ENHANCEMENTS. </span>
                                Licensee will make no efforts to reverse
                                engineer the Software, or make any modifications or enhancements without Licensor's express
                                written consent.
                            </p>
                            <p className='mb-6 '>
                                <span className='font-black'>10. WARRANTY LIMITATIONS. </span>
                                LICENSOR WARRANTS THAT THE SOFTWARE
                                WILL FUNCTION IN SUBSTANTIAL ACCORDANCE WITH THE DESCRIPTION AND
                                SPECIFICATIONS SET FORTH IN ATTACHED SCHEDULE A. THE WARRANTY
                                GRANTED HEREIN IS IN LIEU OF ALL OTHER WARRANTIES, EXPRESS OR IMPLIED,
                                INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF
                                MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. 
                            </p>
                            <p className='mb-6'>
                                <span className='font-black'>11. REMEDY LIMITATIONS. </span>
                                Licensor's entire liability and Licensor's sole and exclusive
                                remedy for breach of the foregoing warranty shall be Licensor's option to either: 
                            </p>
                            <p className='mb-6 ml-10'>- return to Licensee the license fee for the period in which the Software did not perform according to this warranty, or</p>
                            <p className='mb-6 ml-10'>- repair the defects or replace the Software. </p>
                            <p className='mb-6 '>
                                <span className='font-black'>12. DAMAGE LIMITATIONS. </span>
                                NEITHER PARTY SHALL BE LIABLE TO THE OTHER
                                FOR INDIRECT, SPECIAL, CONSEQUENTIAL OR INCIDENTAL DAMAGES,
                                INCLUDING LOSS OF PROFITS, AND LICENSOR'S LIABILITY TO LICENSEE FOR ANY
                                OTHER DAMAGES RELATING TO OR ARISING OUT OF THIS AGREEMENT
                                WHETHER IN CONTRACT, TORT, OR OTHERWISE WILL BE LIMITED TO THE
                                AMOUNT RECEIVED BY LICENSOR FROM LICENSEE AS COMPENSATION FOR THE
                                SOFTWARE DURING THE 1 MONTH PERIOD IMMEDIATELY PRIOR TO THE TIME
                                SUCH CLAIM AROSE. 
                            </p>
                            <p className='mb-4'>
                                <span className='font-black'>13. CONFIDENTIALITY. </span>
                                Licensee will treat the Software as a trade secret and proprietary
                                know-how belonging to Licensor that is being made available to Licensee in confidence.
                            </p>
                            <p className='mb-6'>
                                Licensee agrees to treat the Software with at least the same care as it treats its own confidential
                                or proprietary information. 
                            </p>
                            <p className='mb-6 '>
                                <span className='font-black'>14. ARBITRATION. </span>
                                The parties agree to submit any dispute under this License to binding
                                arbitration under the rules of the American Arbitration Association in the following location:
                                California. Judgement upon the award rendered by the arbitrator may be entered in any court
                                with jurisdiction to do so. 
                            </p>
                            <p className='mb-6 '>
                                <span className='font-black'>15. ATTORNEY FEES. </span>
                                If any legal action is necessary to enforce this License, the prevailing
                                party shall be entitled to reasonable attorney fees, costs and expenses in addition to any other
                                relief to which it may be entitled.
                            </p>
                            <p className='mb-6 font-black'>
                                <span className='font-black'>16. GENERAL PROVISIONS.  </span>
                            </p>
                            <ol data-counter="0" class={`list-alpha mx-16 relative ${styles.orderListAlphaWithBrackets}`} >
                                <li className='my-4'>
                                    <span className="underline ">Complete Agreement:</span> This License Agreement together with all schedules referred to
                                    in this Agreement, all of which are incorporated herein by reference, constitutes the sole and
                                    entire Agreement between the parties. This Agreement supersedes all prior understandings,
                                    agreements, representations and documentation relating to the subject matter of this Agreement. 
                                </li>
                                <li className='my-4'>
                                    <span className="underline ">Modifications:</span> Modifications and amendments to this Agreement, including any
                                    exhibit, schedule or attachment hereto, shall be enforceable only if in writing and signed by
                                    authorized representatives of both parties.       
                                </li>
                                <li className='my-4'>
                                    <span className="underline ">Applicable law:</span> This License will be governed by the laws of the State of California.
                                </li>
                                <li className='my-4'>
                                    <span className="underline ">Notices:</span> All notices and other communications given in connection with this License
                                    shall be in writing and shall be deemed given as follows: 
                                </li>
                            </ol>
                            <p className='ml-20 my-4'> - When delivered personally to the recipient's address as appearing in the introductory paragraph to this License; </p>
                            <p className='ml-20 my-4'> - Three days after being deposited in the United States mail, postage prepaid to the recipient's address as appearing in the introductory paragraph to this License; or  </p>
                            <p className='ml-20 my-4'> - When sent by fax or telex to the last fax or telex number of the recipient known to the
                                party giving notice. Notice is effective upon receipt provided that a duplicate copy of the
                                notice is promptly given by first-class or certified mail or the recipient delivers a written
                                confirmation of receipt. 
                            </p>
                            <p className='ml-20 my-4'>Any party may change its address appearing in the introductory paragraph to this License by given notice of the change in accordance with this paragraph. </p>
                            <ol data-counter="var(--my-counter)" class={`mx-16 mb-6 relative ${styles.orderListAlphaWithBrackets} ${styles.orderListAlphaWithBracketsSecond}`} >
                                <li className='my-4'>
                                    <span className="underline ">No Agency:</span> Nothing contained herein will be construed as creating any agency,
                                    partnership, joint venture or other form of joint enterprise between the parties.
                                </li>
                            </ol>
                            <p className='mb-6 '>
                                <span className='font-black'>17. ASSIGNMENT. </span>
                                The rights conferred by this License shall not be assignable by the Licensee
                                without Licensor's prior written consent. Licensor may impose a reasonable license fee on any
                                such assignment.
                            </p>
                        </div>
                    </div>
                    <div className='gap-10 flex my-4'>
                        <div className="bg-gray-400 w-1/2 ">
                            <SignatureCanvas  ref={signature} penColor='green' canvasProps={{ style: { width: "100%", height: 100 }, className: 'sigCanvas' }} />
                        </div>
                        <div className='w-1/2 '>
                            <div>
                                <input type="text" value={printName} onChange={(e) => { setPrintName(e.target.value)}} className='bg-gray-400 placeholder-white text-white py-2 px-3 w-full' placeholder='PRINT NAME' />
                            </div>
                            <div className='flex mt-4 gap-10'>
                                <div className='w-1/2'>
                                    <input type="date" value={date} onChange={(e) => { setDate(e.target.value)}} className='bg-gray-400 placeholder-white text-white py-2 px-3 w-full' />
                                </div>
                                <div className='w-1/2'>
                                    <input type="text" value={title} onChange={(e) => { setTitle(e.target.value)}} className='bg-gray-400 placeholder-white text-white py-2 px-3 w-full' placeholder='TITLE' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex my-4'>
                        <input type="checkbox" className={`form-checkbox h-5 w-5 border-accent-1`} style={{marginRight:'20px'}} value={agreeTandC} onChange={(e) => (setAgreeTandC(prev => !prev))} />
                        <div className='text-white'>by signing and clicking “confirm” button below, i agree to all the terms and conditions</div>
                    </div>
                    <button type="submit" className={styles.formButton}>CONFIRM</button>
                </form>
            </div>
        )}
        <Loading message={"Loading"} ready={ready} />
    </>
  )
}

export default UserAgreement
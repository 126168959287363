import React, { createContext, useContext, useEffect, useState } from 'react'
import PatientNavbar from '../components/PatientNavbar';
import { Link, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Loading from './Loading';
import lessThanIcon from "../assets/images/lessThanIcon.svg";
import greaterThanIcon from "../assets/images/greaterThanIcon.svg";
import GlobalImageView from '../components/OnePageConsultComponents/GlobalImageView';
import dayjs from 'dayjs';
import CloseupImageView from '../components/OnePageConsultComponents/CloseupImageView';
import GlobalImageCompare from '../components/OnePageConsultComponents/GlobalImageCompare';
import CloseupImageCompare from '../components/OnePageConsultComponents/CloseuplmageCompare';
import GroReportView from '../components/OnePageConsultComponents/GroReportView';

const changeAnalysis = (history, direction, currentIndex, analyses) => {
	const url = window.location.pathname;
	const patientId = url.match(/\/patient\/(\d+)\//)[1];
	const consultId = url.match(/\/consult\/(\d+)\//)[1];
	const subTypeMatch = url.match(/\/closeup\/([^/]+)/);
	const subType = subTypeMatch ? subTypeMatch[1] : null;
	const isComparison = url.includes('/compare/');
	const isGroReport = url.endsWith('/gro_reprot');
	let newIndex;
  
	if (direction === 'prev' && currentIndex > 0) {
	  newIndex = currentIndex - 1;
	} else if (direction === 'next' && currentIndex < analyses.length - 1) {
	  newIndex = currentIndex + 1;
	} else {
	  return;
	}
  
	if (isGroReport) {
	  history.push(`/patient/${patientId}/consult/${newIndex}/gro_reprot`);
	} else if (url.endsWith('/global')) {
	  if (isComparison) {
		history.push(`/patient/${patientId}/consult/${newIndex}/compare/global`);
	  } else {
		history.push(`/patient/${patientId}/consult/${newIndex}/global`);
	  }
	} else if (url.includes('/closeup/')) {
	  if (isComparison) {
		history.push(`/patient/${patientId}/consult/${newIndex}/compare/closeup/${subType}`);
	  } else {
		history.push(`/patient/${patientId}/consult/${newIndex}/closeup/${subType}`);
	  }
	}
  };
  
  
  
export const ChangeAnalysisContext = createContext({
	changeAnalysis: changeAnalysis
});

const OnePageConsult = () => {
	const history = useHistory();

	const globalMatch = useRouteMatch("/patient/:patient_id/consult/:analysis_id/global");
  	const closeupMatch = useRouteMatch("/patient/:patient_id/consult/:analysis_id/closeup/:subType");

	const { patient_id, analysis_id } = useParams();
	const analysis_id_int = parseInt(analysis_id);

	const [message, setMessage] = useState("");
	const [ready, setReady] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [patientReferrals, setPatientReferrals] = useState(null);
	const [hairAnalysis, setHairAnalysis] = useState([]);

	const { changeAnalysis } = useContext(ChangeAnalysisContext);

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API}/hair_analysis/all/v2?patient_id=${patient_id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		})
		.then((response) => response.json())
		.then((response) => {
			if (response.success) {
				setFirstName(response.firstName);
				setLastName(response.lastName);
				setHairAnalysis(response.hair_analysis);
				//   setCurrentId(0);
				setPatientReferrals(response.referral);
				
			} else {
				setMessage(response.message);
			}
				setReady(true);
		})
		.catch((error) => {
			console.error(error);
			setMessage("Some Error Occured. Please Try Again Later");
		});
	}, [patient_id]);
	  

	return (
		<>
			<main
				className={`mx-auto container flex flex-col p-4 ${
				ready ? "" : "hidden"
				}`}
			>
				<PatientNavbar id={patient_id} tab={6} />
				{message && <p className="mt-8 text-center text-red-600">{message}</p>}
				<section className="mt-6">
					<div>
						{patientReferrals ? (
						<h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
							{`${firstName} ${lastName} referred by ${patientReferrals.referred_by}`}
						</h1>
						) : (
						<h1 className="px-12 flex  justify-center mt-4 text-center font-bold text-white text-opacity-85 text-base leading-5 tracking-widest opacity-80" style={{ letterSpacing: '1.6px' }}>
							{`${firstName} ${lastName}`}
						</h1>
						)}
						<div className="flex items-center md:justify-evenly flex-col md:flex-row">
							<div className=" flex items-center">
								<button
									className="font-bold text-2xl focus:outline-none"
									onClick={function () {
										changeAnalysis(history, 'prev', analysis_id_int, hairAnalysis);
									}}
								>
									
									<img src={lessThanIcon} className="h-6 w-6" />
								</button>
								<p className="mx-4 text-text-8">
								{hairAnalysis[analysis_id_int]
									? dayjs
										.unix(hairAnalysis[analysis_id_int].created_at)
										.format("MM-DD-YY")
									: ""}
								</p>
								<button
									className="font-bold text-2xl focus:outline-none"
									onClick={function () {
										changeAnalysis(history, 'next', analysis_id_int, hairAnalysis);
									}}
								>
									<img src={greaterThanIcon} className="h-6 w-6" />
								</button>
							</div>
						</div>
					</div>
					<div
						className="p-8 pt-0 mt-4 bg-background-5 overflow-hidden relative"
						style={{ minHeight: "var(--height-2)" }}
					>
						<div>
							<ChangeAnalysisContext.Provider value={{ changeAnalysis: changeAnalysis }}>
								<Switch>
									<Route path="/patient/:patient_id/consult/:analysis_id/global" exact>
										<GlobalImageView analysis={hairAnalysis[analysis_id_int]} />
									</Route>
									<Route path="/patient/:patient_id/consult/:analysis_id/closeup/:subType" exact>
										<CloseupImageView analysis={hairAnalysis[analysis_id_int]} />
									</Route>
									<Route path="/patient/:patient_id/consult/:analysis_id/compare/global" exact>
										<GlobalImageCompare analyses={hairAnalysis} />
									</Route>
									<Route path="/patient/:patient_id/consult/:analysis_id/compare/closeup/:subType" exact>
										<CloseupImageCompare analyses={hairAnalysis} />
									</Route>
									<Route path="/patient/:patient_id/consult/:analysis_id/gro_reprot" exact>
										<GroReportView analyses={hairAnalysis} />
									</Route>
								</Switch>
							</ChangeAnalysisContext.Provider>
						</div>
						{(globalMatch || closeupMatch) && (
							<div className='flex justify-center mx-32 gap-20 my-8'>
								<div className='w-1/3'></div>
								<div className='w-1/3 text-center'>
									<Link to={`/patient/${patient_id}/consult/${analysis_id_int}/gro_reprot`} className="bg-transparent border border-solid py-2 px-4 w-full font-bold text-base font-sans tracking-wider leading-5 uppercase" style={{ color: '#D0D4D7', borderColor: 'rgba(246,247,250,0.4)' }}>Gro Reports</Link>
								</div>
								<div className='w-1/3'></div>
							</div>
						)}
					</div>
				</section>
			</main>
			<Loading message={"Loading"} ready={ready} />
		</>
	)
}

export default OnePageConsult
import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import ShowHeadAvatar from './ShowHeadAvatar';
import GroIndexGraph from './GroIndexGraph';
import ImageLoader from '../ImageLoader';


const CloseupImageView = ({ analysis }) => {
	const history = useHistory();

    const { patient_id, analysis_id, subType } = useParams();
	const analysis_id_int = parseInt(analysis_id);

	return (
		<div>
			<div class="text-center font-bold text-base font-sans tracking-wider text-text-15 opacity-80 mt-12">
				Closeup Hair Growth Tracking
			</div>
			<div className='text-center font-normal text-base font-sans tracking-wider text-text-15 opacity-80 mb-10 capitalize'>
				({subType})
			</div>
			<div className='mx-20 flex gap-20'>
				<div className='w-1/3'>
					<GroIndexGraph analysis={analysis} forType={subType}/>
				</div>
				<div className='w-1/3 flex justify-center align-center'>
					{/* {displayImage()} */}
					{/* <img src={Closeup} className='cursor-pointer' onDoubleClick={() => {history.push(`/patient/${patient_id}/consult/${analysis_id_int}/compare/closeup/${subType}`)}}/> */}
					{analysis?.images?.closeup.raw && <ImageLoader img={analysis?.images?.closeup?.raw?.[subType]?.[0]} secondaryImg={analysis?.images?.closeup?.ml?.[subType]?.[0]} onDoubleClick={() => {history.push(`/patient/${patient_id}/consult/${analysis_id_int}/compare/closeup/${subType}`)}} />}
				</div>
				<div className='w-1/3 flex justify-evenly flex-row'>
					<ShowHeadAvatar showHead={['left','right'].includes(subType) ? subType : 'top'} />
				</div>
			</div>
		</div>
	)
}

export default CloseupImageView
import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import ShowHeadAvatar from './ShowHeadAvatar';
import GroIndexGraph from './GroIndexGraph';
import ImageLoader from '../ImageLoader';


const GlobalImageView = ({ analysis }) => {
	const history = useHistory();

    const { patient_id, analysis_id } = useParams();
	const analysis_id_int = parseInt(analysis_id);

	return (
		<div>
			<div className="text-center font-bold text-base font-sans tracking-wider text-text-15 opacity-80 mt-12">
				Global Thinning Hair Assessment
			</div>
			<div className='text-center font-normal text-base font-sans tracking-wider text-text-15 opacity-80 mb-10'>
				(Crown)
			</div>
			<div className='mx-20 flex gap-20'>
				<div className='w-1/3'>
					<GroIndexGraph analysis={analysis} forType="crown" />
				</div>
				<div className='w-1/3 flex justify-center align-center'>
					{/* {displayImage()} */}
					{/* <img src={Global} className='cursor-pointer' onDoubleClick={() => {history.push(`/patient/${patient_id}/consult/${analysis_id_int}/compare/global`)}}/> */}
					{analysis?.images?.global && <ImageLoader img={analysis?.images?.global?.ml} onDoubleClick={() => {history.push(`/patient/${patient_id}/consult/${analysis_id_int}/compare/global`)}} />}
				</div>
				<div className='w-1/3 flex justify-evenly flex-row'>
					<ShowHeadAvatar showHead="top" />
				</div>
			</div>
		</div>
	)
}

export default GlobalImageView
import React from 'react'
import { useParams } from 'react-router-dom';

const GroIndexGraph = ({analsysis, forType}) => {

	return (
		<div className='flex flex-col h-full'>
			<div className="text-center text-text-15  font-bold text-base leading-5 tracking-wider opacity-60 w-2/3" style={{ fontFamily: 'Arial', fontSize: '16px', lineHeight: '22px', letterSpacing: '1px' }}>
				GRO Index
			</div>
			<div className="text-center text-text-15  font-normal text-base leading-5 tracking-wider uppercase opacity-60 w-2/3" style={{ fontFamily: 'Arial', fontSize: '16px', lineHeight: '22px', letterSpacing: '1px' }}>
				({forType})
			</div>
			<div className='relative flex-grow flex justify-center mt-6 w-2/3'>
				<div className='relative w-24 text-center h-full py-6' style={{ backgroundColor: '#7CD8EF' }}>
					<p className="text-center font-bold text-black text-3xl leading-10 tracking-tighter" style={{ fontFamily: 'Arial', fontSize: '30px', lineHeight: '10px', letterSpacing: '-0.6px', opacity: '1' }}>12</p>
					<div className='absolute text-center font-bold text-md leading-6 text-white letter-spacing-[0.36px] font-roboto top-2 w-24' style={{ right: '-142px', letterSpacing: '0.32px', opacity: 0.85}}>
						+91-100% <span className='font-normal'>(04-17-22)</span>
					</div>
				</div>
				<div className='absolute bottom-0 flex flex-col gap-2'>
					<div class="text-center font-bold text-white text-base leading-5 border-2 border-white py-3 px-4" style={{fontFamily: 'Arial', boxShadow: '0px 3px 6px #000000', backgroundColor: '#1B9DBE'}}>
						Healthy
					</div>
					<div class="text-center font-bold text-white text-base leading-5 border-2 border-white py-3 px-4" style={{fontFamily: 'Arial', boxShadow: '0px 3px 6px #000000', backgroundColor: '#EF8679'}}>
						Early Thinning
					</div>
					{/* <div class="text-center font-bold text-white text-base leading-5 border-2 border-white py-3 px-4" style={{fontFamily: 'Arial', boxShadow: '0px 3px 6px #000000', backgroundColor: '#EF8679'}}>
						Moderate Thinning
					</div> */}
					<div class="text-center font-bold text-white text-base leading-5 border-2 border-white py-3 px-4" style={{fontFamily: 'Arial', boxShadow: '0px 3px 6px #000000', backgroundColor: '#EF5656'}}>
						Visible Thinning
					</div>
					<div class="relative text-center font-bold text-white text-base leading-5 border-2 border-white py-3 px-4" style={{fontFamily: 'Arial', boxShadow: '0px 3px 6px #000000', backgroundColor: '#EF5656'}}>
						Advanced Thinning
						<div className='absolute top-1/2 transform -translate-y-1/2 -translate-x-3 text-center font-normal text-white text-opacity-85 text-base leading-5 tracking-wider font-roboto' style={{ right: '-100px', letterSpacing: '0.32px', opacity: 0.85}}>
							(01-17-23)
						</div>
					</div>
					<div class="relative text-center font-bold text-white text-base leading-5 border-2 border-white py-3 px-4" style={{fontFamily: 'Arial', boxShadow: '0px 3px 6px #000000', backgroundColor: '#EF5656'}}>
						Severe Thinning
						<div className='absolute top-1/2 transform -translate-y-1/2 -translate-x-3 text-center font-normal text-white text-opacity-85 text-base leading-5 tracking-wider font-roboto' style={{ right: '-100px', letterSpacing: '0.32px', opacity: 0.85}}>
							(09-17-22)
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default GroIndexGraph
import React, { useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import CrossGrey from "../../assets/images/CrossGrey.svg";
import dayjs from 'dayjs';
import ShareGrey from '../../assets/images/ShareGrey.svg'
import PrintGrey from '../../assets/images/PrintGrey.svg'
import DownloadGrey from '../../assets/images/DownloadGrey.svg'

const GroReportView = ({ analyses }) => {
    const { patient_id, analysis_id, subType } = useParams();
	const analysis_id_int = parseInt(analysis_id);

    return (
        <div className='p-10'>
            <div className='absolute top-5 right-5'>
				<Link to={`/patient/${patient_id}/consult/${analysis_id}/global`}>
					<img src={CrossGrey} className='h-5 w-5' />
				</Link>
			</div>
            <div className='mb-8'>
                <h1 className='text-center text-xl font-bold text-20 leading-21 font-normal font-arial tracking-widest text-white opacity-70'>GRO report</h1>
            </div>
            <div className='flex gap-16'>
                <div className='w-1/4'>
                    {
                        analyses.map((analysis,index) => {
                            return (
                                <p className='mb-3'>
                                    <Link to={`/patient/${patient_id}/consult/${index}/gro_reprot`} className={`font-bold text-base leading-5 font-normal font-roboto tracking-widest text-white ${index === analysis_id_int ? 'opacity-80' : 'opacity-40'} `}>
                                        {
                                            dayjs
                                            .unix(analysis.created_at)
                                            .format("MM-DD-YY")
                                        }
                                    </Link>
                                </p>
                            )
                        })
                    }
                </div>
                <div className='w-1/2 flex'>
                    <div className='w-full h-full bg-white'>PDF goes here</div>
                </div>
                <div className='w-1/4'>
                    <div className='flex gap-5'>
                        <img className='cursor-pointer' src={ShareGrey} />
                        <img className='cursor-pointer' src={DownloadGrey} />
                        <img className='cursor-pointer' src={PrintGrey} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GroReportView;
import React, { useState, useEffect } from 'react';
import PatientNavbar from '../components/PatientNavbar';
import PresntationNavbar from '../components/PresentationNavbar';
import Loading from './Loading';
import { useParams } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';

const HairData = () => {
  const { id } = useParams();
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [viewMode, setViewMode] = useState('front');
  const [hairDensityViewMode, setHairDensityViewMode] = useState('A');
  const [imageViewMode, setImageViewMode] = useState('raw');
  const [hairData, setHairData] = useState([]);
  const [currentId, setCurrentId] = useState(1);
  const [hairThickness, setHairThickness] = useState([]);
  const [hairDensity, sethairDensity] = useState([]);
  const [label, setLabel] = useState([]);
  const [patientReferrals, setPatientReferrals] = useState(null);
  // const [data, setData] = useState(null);
  // const label = ["apple",'oranges']
  // const dataset = ["10",'17']
  const toggleImageViewMode = () => {
    const mode = imageViewMode === 'raw' ? 'ai' : 'raw';
    setImageViewMode(mode);
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/hair_analysis/all?patient_id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setCurrentId(0);
          setPatientReferrals(response.referral);

          const data = response.hair_analysis;
          let hair = [];
          data.forEach((d, i) => {
            let closeup = [],
              api = [];
            d.images['3x_closeup'].forEach(element => {
              var subType = element.subType;
              if (element.subType === 'occipital') {
                subType = 'back';
              }
              if(element.status === 'invalid'){
                closeup[subType] = {
                  id: element.id,
                  image: 'invalid'
                };
              }else if(element.status === 'invalidated'){
                closeup[subType] = {
                  id: element.id,
                  image: 'invalidated',
                  reason: element?.reason ? `This image is invalid. Reason: ${element.reason.join(', ')}` : null
                };
              }else{
                closeup[subType] = {
                  id: element.id,
                  image: element.image_path
                };
              }
            });
            d.images.closeup_api.forEach(element => {
              if (element.paint === 1) {
                var subType = element.subType;
                if (element.subType === 'occipital') {
                  subType = 'back';
                }
                api[subType] = {
                  image: element.image_path,
                  is_draft: element.is_draft
                };
              }
            });
            hair[i] = {
              ...d.gromeasure,
              image: closeup,
              api: api,
              date: dayjs.unix(d.created_at).format('MM-DD-YY'),
            };
          });

          const entries = hair;
          setLabel(Object.keys(entries));
          const areas = ['front', 'crown', 'vertex', 'back', 'right', 'left'];
          const hairData = [];
          let hairThicknessData = [];
          let hairDensityData = [];
          for (let i = 0; i < Object.keys(entries).length; i++) {
            hairData[i] = [];
            hairData[i]['created_at'] = entries[i].date;
            hairData[i]['data'] = [];

            for (let j = 0; j < areas.length; j++) {
              hairData[i]['data'][areas[j]] = {
                id: i + 1,
                reason: entries[i]?.image?.[areas[j]]?.reason || null,
                rawimg: entries[i]?.image?.[areas[j]]?.image || null ,
                aiimg: entries[i]?.api[areas[j]]?.image || null,
                isAiDraft: entries[i]?.api[areas[j]]?.is_draft || null,
                All: entries[i][areas[j]]?.all || null,
                T: entries[i][areas[j]]?.t || null,
                V: entries[i][areas[j]]?.v || null,
                TV: entries[i][areas[j]]?.tv || null,
                FU: entries[i][areas[j]]?.fu || null,
                HT: entries[i][areas[j]]?.hairDiameter || null,
              };
            }
          }
          setHairData(hairData);
          for (let i = 0; i < areas.length; i++) {
            hairThicknessData[areas[i]] = hairData.map(arr => {
              return arr.data[areas[i]].HT;
            });
            hairDensityData[areas[i]] = [];
            hairDensityData[areas[i]]['A'] = hairData.map(arr => {
              return arr.data[areas[i]].All;
            });
            hairDensityData[areas[i]]['T'] = hairData.map(arr => {
              return arr.data[areas[i]].T;
            });
            hairDensityData[areas[i]]['V'] = hairData.map(arr => {
              return arr.data[areas[i]].V;
            });
            hairDensityData[areas[i]]['TV'] = hairData.map(arr => {
              return arr.data[areas[i]].TV;
            });
            hairDensityData[areas[i]]['FU'] = hairData.map(arr => {
              return arr.data[areas[i]].FU;
            });
          }
          setHairThickness(hairThicknessData);
          sethairDensity(hairDensityData);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
      });
  }, [id]);

  const PrintImg = () => {
    var mode = viewMode;
    // if(viewMode === 'back'){
    //   mode = 'occipital';
    // }
    if (
      hairData[currentId] &&
      imageViewMode === 'raw' &&
      hairData[currentId]['data'][mode]['rawimg'] &&
      hairData[currentId]['data'][mode]['rawimg'] !== 'invalid' &&
      hairData[currentId]['data'][mode]['rawimg'] !== 'invalidated' 
    ) {
      return (
        <img
          className="w-full mt-2"
          style={{ width: '221px' /*height: "296px"*/ }}
          src={`${hairData[currentId]["data"][mode]["rawimg"]}?width=286`}
          alt=""
        />
      );
    } else if (
      hairData[currentId] &&
      imageViewMode === 'ai' &&
      hairData[currentId]['data'][mode]['aiimg'] &&
      hairData[currentId]['data'][mode]['rawimg'] !== 'invalid' &&
      hairData[currentId]['data'][mode]['rawimg'] !== 'invalidated' 
    ) {
      return hairData[currentId]['data'][mode]['aiimg'] === '0' ? (
        <div className="text-center mt-4">Image being processed.</div>
      ) : (
        <div>
          <img
            className="w-full mt-2 bg-black"
            style={{ width: '221px' /*height: "296px"*/ }}
            src={hairData[currentId]['data'][mode]['aiimg']}
            alt=""
          />
          {hairData[currentId]['data'][mode]['isAiDraft'] === '1' && <div className="text-center" style={{ width: '221px' }}>This result is being validated.</div>}
          {hairData[currentId]['data'][mode]['isAiDraft'] === '0' && <div className="text-center" style={{ width: '221px' }}>Verification of image complete.</div>}
          
        </div>
      );
    } else if ( 
      hairData[currentId] && 
      hairData[currentId]['data'][mode]['rawimg'] === 'invalid' 
    ){
      return <div className="text-center mt-4">Image is being validated.</div>;
    } else if ( 
      hairData[currentId] && 
      hairData[currentId]['data'][mode]['rawimg'] === 'invalidated' 
    ){
      return <div className="text-center mt-4">{hairData[currentId]['data'][mode]['reason']}</div>;
    }else {
      return <div className="text-center mt-4">No image available</div>;
    }
  };
  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={6} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section>
          {patientReferrals ? (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName} referred by ${patientReferrals.referred_by}`}
            </h1>
          ) : (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName}`}
            </h1>
          )}
          <div className="flex items-center md:justify-evenly flex-col md:flex-row">
            <div className=" flex items-center">
              <button
                className="font-bold text-2xl focus:outline-none"
                onClick={function () {
                  if (currentId > 0) {
                    setCurrentId(currentId - 1);
                  }
                }}
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  stroke="#bfc5ca"
                  strokeWidth="1.25"
                >
                  <path
                    fill="#bfc5ca"
                    d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                  />
                </svg>
              </button>
              <p className="mx-4">
                {hairData[currentId] ? hairData[currentId].created_at : ''}
              </p>
              <button
                className="font-bold text-2xl focus:outline-none"
                onClick={function () {
                  if (currentId + 1 < hairData.length) {
                    setCurrentId(currentId + 1);
                  }
                }}
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  stroke="#bfc5ca"
                  strokeWidth="1.25"
                >
                  <path
                    fill=" #bfc5ca"
                    d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </section>
        <div
          className="p-12 mt-4 bg-background-5 overflow-hidden"
          style={{ height: 'var(--height-2)' }}
        >
          <div className="flex items-center justify-center mx-auto font-font-2 text-text-8">
            <button
              className={`${
                viewMode === 'front' && 'text-text-7'
              } focus:outline-none mx-2`}
              onClick={() => {
                setViewMode('front');
              }}
            >
              Front
            </button>
            <button
              className={`${
                viewMode === 'crown' && 'text-text-7'
              } focus:outline-none mx-2`}
              onClick={() => {
                setViewMode('crown');
              }}
            >
              Crown
            </button>
            <button
              className={`${
                viewMode === 'vertex' && 'text-text-7'
              } focus:outline-none mx-2`}
              onClick={() => {
                setViewMode('vertex');
              }}
            >
              Vertex
            </button>
            <button
              className={`${
                viewMode === 'back' && 'text-text-7'
              } focus:outline-none mx-2`}
              onClick={() => {
                setViewMode('back');
              }}
            >
              Occipital
            </button>
            <button
              className={`${
                viewMode === 'left' && 'text-text-7'
              } focus:outline-none mx-2`}
              onClick={() => {
                setViewMode('left');
              }}
            >
              Left
            </button>
            <button
              className={`${
                viewMode === 'right' && 'text-text-7'
              } focus:outline-none mx-2`}
              onClick={() => {
                setViewMode('right');
              }}
            >
              Right
            </button>
          </div>
          <div className="flex items-center justify-center mx-auto mt-10">
            <table className="table-auto border-opacity-40 border border-gray mt-2">
              <tbody>
                <tr>
                  <td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
                    HD/A :{' '}
                    {hairData[currentId]
                      ? hairData[currentId]['data'][viewMode]['All']
                      : ''}
                    /cm2
                  </td>
                  <td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
                    HD/T :{' '}
                    {hairData[currentId]
                      ? hairData[currentId]['data'][viewMode]['T']
                      : ''}
                    /cm2
                  </td>
                  <td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
                    HD/V :{' '}
                    {hairData[currentId]
                      ? hairData[currentId]['data'][viewMode]['V']
                      : ''}
                    /cm2
                  </td>
                </tr>
                <tr>
                  <td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
                    T/V :{' '}
                    {hairData[currentId]
                      ? hairData[currentId]['data'][viewMode]['TV']
                      : ''}
                  </td>
                  <td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
                    HD/FU :{' '}
                    {hairData[currentId]
                      ? hairData[currentId]['data'][viewMode]['FU']
                      : ''}
                    /cm2
                  </td>
                  <td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
                    HT :{' '}
                    {hairData[currentId]
                      ? hairData[currentId]['data'][viewMode]['HT']
                      : ''}{' '}
                    mm
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="grid grid-cols-11 gap-4 mt-12">
            <div className="col-start-1 col-end-5 mx-12">
              <p className="text-base font-font-2 text-text-2 font-normal">
                Hair Density (HD)
              </p>
              <div className="mx-auto">
                <button
                  className={`${
                    hairDensityViewMode === 'A' && 'text-text-2 '
                  } text-sm font-normal focus:outline-none mr-2 font-font-2 `}
                  onClick={() => {
                    setHairDensityViewMode('A');
                  }}
                >
                  A
                </button>
                <button
                  className={`${
                    hairDensityViewMode === 'T' && 'text-text-3 '
                  } text-sm font-normal focus:outline-none mx-2 font-font-2`}
                  onClick={() => {
                    setHairDensityViewMode('T');
                  }}
                >
                  T
                </button>
                <button
                  className={`${
                    hairDensityViewMode === 'V' && 'text-text-3 '
                  } text-sm font-normal focus:outline-none mx-2 font-font-2`}
                  onClick={() => {
                    setHairDensityViewMode('V');
                  }}
                >
                  V
                </button>
                <button
                  className={`${
                    hairDensityViewMode === 'TV' && 'text-text-3 '
                  } text-sm font-normal focus:outline-none mx-2 font-font-2`}
                  onClick={() => {
                    setHairDensityViewMode('TV');
                  }}
                >
                  T/V
                </button>
                <button
                  className={`${
                    hairDensityViewMode === 'FU' && 'text-text-3 '
                  } text-sm font-normal focus:outline-none mx-2 font-font-2`}
                  onClick={() => {
                    setHairDensityViewMode('FU');
                  }}
                >
                  FU
                </button>
              </div>
              <div style={{ width: 350, height: 250, marginTop: 35 }}>
                <Bar
                  data={{
                    labels: label,
                    datasets: [
                      {
                        data:
                          (hairDensity[viewMode] &&
                            hairDensity[viewMode][hairDensityViewMode]) ||
                          [],
                        backgroundColor: label.map((l, i) => {
                          if (i === currentId) {
                            return '#ef8679';
                          }
                          return '#7a686f';
                        }),
                        borderColor: label.map((l, i) => {
                          if (i === currentId) {
                            return '#ef8679';
                          }
                          return '#7a686f';
                        }),
                        borderWidth: 1,
                        label: '(/cm2)',
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      datalabels: {
                        display: true,
                        color: 'white',
                        align: 'end',
                        anchor: 'end',
                      },
                    },

                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            userCallback: function (l, index, labels) {
                              if (
                                index === currentId ||
                                index === 0 ||
                                index === label.length - 1
                              ) {
                                return l;
                              }
                              return '';
                            },
                          },
                          gridLines: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false,
                            color: '#4b535a',
                            lineWidth: 2,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            userCallback: function (l, index, labels) {
                              return l;
                            },
                            min: 0,
                            max: 250,
                            stepSize: 125,
                          },
                          gridLines: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false,
                            color: '#4b535a',
                            lineWidth: 2,
                          },
                        },
                      ],
                    },

                    legend: {
                      labels: {
                        boxWidth: -15,
                        fontSize: 15,
                        fontColor: '#bfc5ca',
                      },

                      align: 'start',
                      onClick: '',
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                  }}
                />
              </div>
            </div>
            <div
              className="col-start-5 col-end-8 px-4 text-sm"
              style={{ marginLeft: 20 }}
            >
              <div
                className="grid grid-cols-6 gap-6"
                style={{ width: '221px' }}
              >
                <div className="col-start-1 col-end-3">
                  <button
                    className={`${
                      imageViewMode === 'raw' && 'text-text-2'
                    } text-sm font-normal focus:outline-none font-font-2`}
                    onClick={() => {
                      setImageViewMode('raw');
                    }}
                  >
                    Raw
                  </button>
                </div>
                <div className="col-end-7 col-span-2 text-right">
                  <button
                    className={`${
                      imageViewMode === 'ai' && 'text-text-2'
                    } text-sm font-normal focus:outline-none font-font-2`}
                    onClick={() => {
                      setImageViewMode('ai');
                    }}
                  >
                    A.I.
                  </button>
                </div>
              </div>
              <div className="cursor-pointer" onClick={toggleImageViewMode}>
                <PrintImg />
              </div>
            </div>
            <div className="col-start-8 col-end-12">
              <p
                className="text-base font-font-2 text-text-2 font-normal mx-12"
                style={{ marginBottom: 25 }}
              >
                Hair Thickness (HT)
              </p>
              <div style={{ width: 350, height: 250, marginTop: 50 }}>
                <Bar
                  data={{
                    labels: label,
                    datasets: [
                      {
                        data: hairThickness[viewMode],
                        backgroundColor: label.map((l, i) => {
                          if (i === currentId) {
                            return '#3de2d2';
                          }
                          return '#267c7f';
                        }),
                        borderColor: label.map((l, i) => {
                          if (i === currentId) {
                            return '#3de2d2';
                          }
                          return '#267c7f';
                        }),
                        borderWidth: 1,
                        label: '(mm)',
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      datalabels: {
                        display: true,
                        color: 'white',
                        align: 'end',
                        anchor: 'end',
                      },
                    },
                    responsive: true,
                    maintainAspectRatio: false,

                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            userCallback: function (l, index, labels) {
                              if (
                                index === currentId ||
                                index === 0 ||
                                index === label.length - 1
                              ) {
                                return l;
                              }
                              return '';
                            },
                          },
                          gridLines: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false,
                            color: '#4b535a',
                            lineWidth: 2,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            userCallback: function (l, index, labels) {
                              return l;
                            },
                            min: 0,
                            max: 0.1,
                            stepSize: 0.05,
                          },
                          gridLines: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false,
                            color: '#4b535a',
                            lineWidth: 2,
                          },
                        },
                      ],
                    },

                    legend: {
                      labels: {
                        boxWidth: -15,
                        fontSize: 15,
                        fontColor: '#bfc5ca',
                      },

                      align: 'start',
                      onClick: '',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>

        <PresntationNavbar id={id} tab={3} />
      </main>
      <Loading message={'Loading Hair Data'} ready={ready} />
    </>
  );
};

export default HairData;

import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosAdd } from "react-icons/io";
//import Loading from '../views/Loading';
import ProcedureForm from "../components/ProcedureForm";
import ShowCases from "./ShowCases";
import lessThanIcon from "../assets/images/lessThanIcon.svg";
import greaterThanIcon from "../assets/images/greaterThanIcon.svg";
import GRORatingsLogo from "../assets/images/GRORatingsLogo.svg";

function ProcedureTypes({
  openGroPlan,
  setOpenGroPlan,
  getTreatmentPlans,
  setReady,
  ref
}) {
  // const ref = useRef();
  //const [message,setMessage] = useState('')
  //const [ready, setReady] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [procedurePdf, setProcedurePdf] = useState({
    isOpen: false,
    data: null,
  });
  const [procedureTypes, setProcedureTypes] = useState(null);
  const [newList, setNewList] = useState(procedureTypes);
  const [procedureForm, setProcedureForm] = useState(false);

  const [adminProcedures, setAdminProcedures] = useState(null);
  const [matchProcedure, setMatchProcedure] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [gender, setGender] = useState("All");
  const [age, setAge] = useState("All");
  const [screen, setScreen] = useState("all_items");

  const [openCases, setOpenCases] = useState(false);
  const [showArrows, setShowArrows] = useState(true);

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
  };

  useEffect(() => {
    let min = 0;
    let max = 200;
      if (age === "All") {
        min = 0;
        max = 200;
      }else if (age === "under 20") {
        min = 0;
        max= 20;
      } else if (age === "over 70") {
        min = 71;
        max = 200
      } else {
        min = age.split("-")[0];
        max = age.split("-")[1];
      }
      
      setReady(false);
      fetch(
        `${process.env.REACT_APP_API}/clinic/templates?gender=${
          gender === "Male" ? "M" : gender === "Female" ? "F" : gender
        }&min_age=${min}&max_age=${max}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setProcedureTypes(response.treatment_procedures);
            setNewList([...response.treatment_procedures,...response.treatment_procedures,...response.treatment_procedures]);
            setAdminProcedures(response.procedures);
          }
        })
        .catch((error) => {
          console.error(error);
          //setMessage('Some Error Occured. Please Try Again Later');
        })
        .finally(() => {
          setReady(true);
        });
  }, [gender, age]);

  const showPdf = (obj, index) => {
    setCurrentIndex(index);
    
    setProcedureForm(false);
    setOpenCases(false);
    if(procedurePdf.isOpen){
      setScreen('all_items');
      setProcedurePdf({
        isOpen: false,
        data: null
      });
      setShowArrows(true);
    }else{
      setScreen('single_item');
      setProcedurePdf({
        isOpen: true,
        data: obj
      });
      setShowArrows(false);
    }
  };
  const openProcedure = (obj, index) => {
    setCurrentIndex(index);
    
    setProcedurePdf({
      isOpen: false,
      data: null
    });
    setOpenCases(false);
    if(procedureForm){
      setScreen('all_items');
      setProcedureForm(false);
      setShowArrows(true);
    }else{
      setScreen('single_item');
      setProcedureForm(true);
      setShowArrows(false);
    }

    const procedure = adminProcedures.filter((item) => item.name === obj.title);
    setMatchProcedure(procedure[0]);
  };

  const cases = (obj, index) => {
    setCurrentIndex(index);
    
    setProcedurePdf({
      isOpen: false,
      data: null
    });
    setProcedureForm(false);
    if(openCases){
      setScreen('all_items');
      setOpenCases(false);
      setShowArrows(true);
    }else{
      setScreen('single_item');
      setOpenCases(true);
      setShowArrows(false);
    }
  };

  return (
    <>
      {/* <Loading message={"Loading Today's Treatment Plan"} ready={ready} /> */}

      <div style={{ width: "100%", margin: "0 auto" }}>
        <div className='flex items-center justify-center gap-10'>
         
          <div className="w-32">
            {
              showArrows && 
                <button
                  className="text-2xl focus:outline-none"
                  onClick={function () {
                    if(currentIndex > 0){
                      setCurrentIndex((prev) => prev - 1);
                    }
                  }}
                >
                  <img src={lessThanIcon} className='w-10 h-10'/>
                </button>
            }
          </div>
         
          <div className="grid justify-center flex-grow grid-cols-3 gap-10">
            {newList &&
              newList.map((item, index) => {
                if(((index === currentIndex || index === currentIndex - 1 || index === currentIndex + 1) && screen === 'all_items') || (screen === 'single_item' && index === currentIndex) )
                {
                  return (
                    <>
                      <div
                      className={`${currentIndex === 0 && index === 0 && screen === 'all_items' ? 'col-start-2' : ''} flex`}
                        style={{
                          // margin: "0 2.4rem",
                          // width: "16rem",
                          padding: "2rem 0rem",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                            padding: "1rem 0rem",
                            color: "black",
                            position: "relative",
                          }}
                        >
                          {/* <div
                            style={{
                              width: "3rem",
                              height: "3rem",
                              border: "1px solid black",
                              borderRadius: "100%",
                              background:
                                item.cases > 100 && item.result > 20
                                  ? "#00CCCC"
                                  : "#BE5504",
                              fontSize: "0.5rem",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "absolute",
                              top: "-1.5rem",
                              left: "-1.5rem",
                            }}
                          >
                            {item.result > 100 && item.cases > 20 ? (
                              <>
                                <div
                                  style={{ fontSize: "1rem", lineHeight: "0.8rem" }}
                                >
                                  GRO
                                </div>
                                <div>RATED</div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{ fontSize: "1rem", lineHeight: "0.8rem" }}
                                >
                                  GRO
                                </div>
                                <div>VERIFIED</div>
                              </>
                            )}
                          </div> */}

                          <div className="text-center font-bold text-2xl leading-15 font-arial tracking-wider text-gray-800 opacity-100 px-3">
                            {item.title}
                          </div>

                          <div className="text-center font-normal text-xl leading-15 font-arial tracking-wider text-gray-800 opacity-100 px-3">{item.sub_title}</div>

                          <img
                            onClick={() => showPdf(item, index)}
                            src={item.image}
                            alt="procedure"
                            className="mt-2 mb-6"
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              height: "10rem",
                              objectFit: "cover",
                            }}
                          />

                          <div
                            className="text-center font-normal font-light text-sm leading-20 font-roboto tracking-wider text-gray-900 opacity-100 mx-3"
                          >
                            {item.description}
                          </div>

                          <div className="mx-6">
                            <div className="flex justify-center  border-b-2 border-gray-500 opacity-100  mt-2 mb-4 py-3 mx-auto ">
                              <img src={GRORatingsLogo} className="w-28 " />
                            </div>
                          </div>

                          <div className="flex flex-col">
                            <div onClick={() => cases(item, index)} className="cursor-pointer w-auto mx-auto px-10 text-center font-normal font-bold text-3xl leading-21 font-roboto tracking-wider text-black opacity-80 border-4 border-gray-500 rounded-3xl border-opacity-69">
                              {item.cases}
                            </div>
                            <p className="mt-2 text-center font-normal text-md leading-21 font-roboto tracking-wider text-gray-900 opacity-69">GRO verified cases</p>
                          </div>

                          <IoIosAdd
                            onClick={() => openProcedure(item, index)}
                            style={{
                              fontSize: "40px",
                              cursor: "pointer",
                              color: "#E8E8E8",
                              position: "absolute",
                              bottom: "-2.5rem",
                              left: "0",
                              right: "0",
                              margin: "0 auto",
                            }}
                          />
                        </div>
                      </div>

                      {procedurePdf.isOpen && (
                        <div
                          className="my-8 w-full col-span-2"
                          style={{
                            backgroundColor: "#E8E8E8",
                            boxSizing: "border-box",
                            padding: "0 1rem",
                          }}
                        >
                          <img
                            src={procedurePdf.data.study_image}
                            alt="procedure"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      )}

                      {procedureForm && (
                        <ProcedureForm
                          getTreatmentPlans={getTreatmentPlans}
                          item={item}
                          matchProcedure={matchProcedure}
                        />
                      )}

                      {openCases && <ShowCases item={item} />}
                    </>
                  );
                }
              })}
          </div>
         
          <div className="w-32">
            {
              showArrows && 
              <button
                className="text-2xl focus:outline-none"
                onClick={function () {
                  if(currentIndex < newList.length - 1){
                    setCurrentIndex((prev) => prev + 1);
                  }
                }}
              >
                <img src={greaterThanIcon} className='w-10 h-10'/>
              </button>
            }
          </div>
			  </div>
      </div>
    </>
  );
}

export default ProcedureTypes;

import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

function ShowCases({ item }) {
  const ref = useRef();

  console.log(item);

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const prevSlide = () => {
    ref.current.slickPrev();
  };

  const nextSlide = () => {
    ref.current.slickNext();
  };

  return (
    <div
      className="my-8 w-full col-span-2 gap-2"
      style={{
        backgroundColor: "rgba(246, 247, 250, 0.9)",
        boxSizing: "border-box",
        padding: "2rem 1.5rem",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {item && item.details.length !== 0 ? (
        <>
          <IoIosArrowBack
            onClick={prevSlide}
            style={{
              fontSize: "40px",
              cursor: "pointer",
              width: "fit-content",
            }}
          />
          <Slider ref={ref} {...settings} style={{ width: "75%" }}>
            {item.details.map((item) => {
              return (
                <div>
                  <div>
                    <div style={{ display: "flex" }} className="gap-2" >
                      <img
                        src={item.before_image.image_path}
                        alt="cases"
                        width="50%"
                      />
                      <img
                        src={item.after_image.image_path}
                        alt="cases"
                        width="50%"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0.5rem 1rem",
                        color: "white",
                        background: "#29A0B1",
                      }}
                    >
                      <div>Before</div>
                      <div>After</div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "4rem",
                      marginTop: "2rem",
                    }}
                    className="text-center font-bold text-xl leading-15 font-arial tracking-wider text-gray-800 "
                  >
                    <div>{item.duration}</div>
                    <div>{item.growth}% hair growth</div>
                  </div>
                </div>
              );
            })}
          </Slider>
          <IoIosArrowForward
            onClick={nextSlide}
            style={{
              fontSize: "40px",
              cursor: "pointer",
              width: "fit-content",
            }}
          />
        </>
      ) : (
        <div style={{ textAlign: "center", width: "100%" }}>
          No cases to show
        </div>
      )}
    </div>
  );
}

export default ShowCases;
